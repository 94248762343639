import { useState } from 'react'
import { Card, Divider, Typography, Box } from '@mui/material'
import parse from 'html-react-parser'

import { ImageCarousel, MediaList } from 'src/components'
import { IImpact, IMedia, Ilocation } from 'src/types'
import { ImpactsList } from './impacts-list.component'
import {
  CardChip,
  CardContentSt,
  CarouselWrapper,
  InfoWrapper,
  LowerContentSt,
  UpperContentSt,
} from './journey-step-card.styled'
import { ExternalLinkIcon } from 'src/assets/icons/v2/ExternalLinkIcon'
import { theme } from 'src/providers/theme-provider/theme.provider'
import {
  AccountHolder,
  DescriptionHolder,
  AvatarImg,
  AvatarSt,
} from '../step-info/step-company.styled'
import { MapPinIcon } from 'src/assets/icons/v2/MapPinIcon'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAccount, useProductJourney } from 'src/hooks'

interface IProps {
  name: string
  image_small: string
  image: string
  impacts: IImpact[]
  media?: IMedia[]
  mainMedia?: IMedia[]
  description?: string
  isMobile?: boolean
  landing_page_show?: boolean
  account_holder?: string
  account_approved?: boolean
  email?: string
  handleScroll: (item: Ilocation) => void
  website?: string
  hideAccountName: boolean
  locations: Ilocation[]
}

export const JourneyStepCard = (props: IProps) => {
  const [, setShowDescr] = useState<boolean>(false)
  const [, i18n] = useTranslation()
  const { productId, simpleProductId } = useParams()
  const { data } = useProductJourney(productId, i18n.language, simpleProductId)
  const { data: account, isLoading } = useAccount(data.product.account_id, i18n.language)

  if (isLoading) {
    return null
  }
  const redirect = () => {
    window.open(props.website, '_blank')
  }

  return (
    <Card>
      <CardContentSt>
        {/* <CardActionArea
            disableTouchRipple={!!props.description}
            disableRipple={!!props.description}
          > */}

        <UpperContentSt>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <CardChip>{props.name}</CardChip>
              {props?.locations && props.locations.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                  }}
                >
                  {props.locations.map((location) => {
                    return (
                      <div
                        key={location.id}
                        onClick={() => props.handleScroll(location)}
                        style={{
                          display: 'flex',
                          marginLeft: '5px',
                          alignItems: 'center',
                          gap: '2px',
                          color: theme.palette.primary.main,
                          textDecoration: 'none',
                          cursor: location.location_address ? 'pointer' : 'default',
                        }}
                      >
                        {location.location_address &&
                          (location.latitude !== 0 || location.longitude !== 0) && (
                            <div style={{ height: '18px', width: '18px' }}>
                              <MapPinIcon color={theme.palette.common.black} />
                            </div>
                          )}
                        <p
                          style={{
                            verticalAlign: 'center',
                            lineHeight: '18px',
                            fontSize: '12px',
                            color:
                              location.location_address &&
                              (location.latitude !== 0 || location.longitude !== 0)
                                ? account.primary_color || theme.palette.primary.main
                                : theme.palette.common.black,
                            borderBottom:
                              location.location_address &&
                              (location.latitude !== 0 || location.longitude !== 0)
                                ? `1px solid ${account.primary_color || theme.palette.primary.main}`
                                : 'none',
                          }}
                        >
                          {location.location_name}
                        </p>
                      </div>
                    )
                  })}
                </Box>
              )}

              {props.landing_page_show && props.account_approved && (
                <AccountHolder>
                  {props.image && !props.image.includes('https://ui-avatars.com/api') && (
                    <AvatarSt style={{ backgroundImage: `url(${props.image})` }} />
                    // <AvatarImg borderRadius={'50%'} width={'48px'} src={props.image} alt="" />
                  )}
                  <DescriptionHolder
                    isLeft
                    sx={{
                      marginLeft: props.image.includes('https://ui-avatars.com/api') ? '3px' : null,
                    }}
                  >
                    {!props.hideAccountName && (
                      <Typography sx={{ fontSize: 14, fontWeight: '700' }}>
                        {props.account_holder}
                      </Typography>
                    )}
                    {props?.website && (
                      <div
                        onClick={redirect}
                        style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            display: 'flex',
                            cursor: 'pointer',
                            alignItems: 'center',
                            gap: '4px',
                            fontSize: 10,
                            color: theme.palette.grey[500],
                          }}
                        >
                          Learn more
                          <ExternalLinkIcon size={12} color={theme.palette.grey[500]} />
                        </Typography>
                      </div>
                    )}
                  </DescriptionHolder>
                </AccountHolder>
              )}
            </div>
            {!props.image_small.includes('https://ui-avatars.com/api') && (
              <Box sx={{ mr: '5px' }}>
                <AvatarImg borderRadius={'6px'} width={'80px'} src={props.image_small} alt="" />
              </Box>
            )}
          </div>

          <InfoWrapper>
            {/* <StepInfo
                  email={props.email}
                  account_approved={props.account_approved}
                  name={props.name}
                  image={props.image_small}
                  location_name={props.location_address}
                  accImg={props.image}
                  landing_page_show={props.landing_page_show}
                  account_holder={props.account_holder}
                  website={props.website}
                /> */}
            {props.impacts.length > 0 && (
              <ImpactsList
                impacts={props.impacts}
                setShowDescr={setShowDescr}
                isMobile={props.isMobile}
              />
            )}
          </InfoWrapper>
        </UpperContentSt>

        {props.mainMedia.length > 0 && (
          <CarouselWrapper>
            <ImageCarousel images={props.mainMedia} isStep />
          </CarouselWrapper>
        )}
        {/* </CardActionArea> */}
        <>
          <LowerContentSt>
            {props.description && props.description !== '<p><br></p>' && (
              <Typography variant="body1" sx={{ margin: '10px 12px' }}>
                {parse(props.description)}
              </Typography>
            )}
            {props.media.length >= 1 && <Divider sx={{ borderColor: 'rgba(0,0,0,0.08);' }} />}
            {props.media.length >= 1 && <MediaList media={props.media} />}
          </LowerContentSt>
        </>
      </CardContentSt>
    </Card>
  )
}
