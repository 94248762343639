import { Route, Routes } from 'react-router-dom'

import Home from './pages/home'
import AuthPage from './pages/auth'
import SplashPage from './pages/splash'
import { JourneyNavContextProvider } from 'src/contexts'
import JourneyPage from './pages/journey'
import ProductPage from './pages//product'
import ImpactsPage from './pages/impacts'
import Account from './pages/account'
import WelcomePage from './pages/welcome'

import { LngWrapper, RoutesWrapper, SideLngsWrapper, AccountSideLngsWrapper } from './components'
import { ROUTES } from './constants'
import 'moment/min/locales'
import { RedirectUrlPage } from './pages/redirect-url'

export function App() {
  return (
    <Routes>
      <Route element={<RoutesWrapper />}>
        <Route path={'/'} element={<WelcomePage />} />
        <Route path={ROUTES.AUTH} element={<AuthPage />} />

        <Route path={ROUTES.SIDE_LANGUAGE} element={<AccountSideLngsWrapper />}>
          <Route path={ROUTES.ACCOUNTS} element={<Account />} />
        </Route>

        <Route path={ROUTES.ACCOUNTS} element={<Account />} />

        <Route path={ROUTES.SIDE_LANGUAGE} element={<SideLngsWrapper />}>
          <Route path={ROUTES.PRODUCTS_WITH_SIMPLE_ID} element={<Home />}>
            <Route index element={<ProductPage />} />
            <Route
              path={ROUTES.JOURNEY}
              element={
                <JourneyNavContextProvider journeyDetails>
                  <JourneyPage />
                </JourneyNavContextProvider>
              }
            />
            <Route path={ROUTES.IMPACTS} element={<ImpactsPage />} />
          </Route>
        </Route>

        <Route path={ROUTES.PRODUCTS_WITH_SIMPLE_ID} element={<Home />}>
          <Route index element={<ProductPage />} />
          <Route
            path={ROUTES.JOURNEY}
            element={
              <JourneyNavContextProvider journeyDetails>
                <JourneyPage />
              </JourneyNavContextProvider>
            }
          />
          <Route path={ROUTES.IMPACTS} element={<ImpactsPage />} />
        </Route>

        {/* Old routes that should be redirected */}
        <Route path={ROUTES.LANGUAGE} element={<LngWrapper />}>
          <Route path={`${ROUTES.PRODUCTS}`}>
            <Route index element={<RedirectUrlPage />} />
            <Route
              path={ROUTES.JOURNEY}
              element={<RedirectUrlPage additionalPath={ROUTES.JOURNEY} />}
            />
            <Route
              path={ROUTES.IMPACTS}
              element={<RedirectUrlPage additionalPath={ROUTES.IMPACTS} />}
            />
          </Route>
        </Route>
        {/* <Route path={ROUTES.LANGUAGE} element={<LngWrapper />}>
          <Route path={`${ROUTES.PRODUCTS}/*`} element={<Home />}>
            <Route index element={<ProductPage />} />
            <Route path={ROUTES.PRODUCT} element={<ProductPage />} />
            <Route
              path={ROUTES.JOURNEY}
              element={
                <JourneyNavContextProvider journeyDetails>
                  <JourneyPage />
                </JourneyNavContextProvider>
              }
            />
            <Route path={ROUTES.IMPACTS} element={<ImpactsPage />} />
          </Route>
          <Route path={ROUTES.ACCOUNTS} element={<Account />} />
        </Route> */}
        <Route path="*" element={<SplashPage />} />
      </Route>
    </Routes>
  )
}
