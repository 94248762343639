import { GoogleMap } from '@react-google-maps/api'
import { IMarkerWithStepName, IStep } from 'src/types'
import { fitMapWithinMarkers } from 'src/utils'
import { mapViewSt } from './map-view.styled'
import { CustomMarker } from './custom-marker'
import { MutableRefObject, useRef } from 'react'
import { useMap } from 'src/contexts/journey-map.context'

interface IProps {
  markers: IMarkerWithStepName[][]
  satelliteView?: boolean
  steps?: IStep[]
  scrollToRef?: MutableRefObject<HTMLSpanElement>
  markerColor?: string
}

export const MapView = ({ markers, satelliteView, scrollToRef, markerColor }: IProps) => {
  const { setMapRef, setMapCenter } = useMap()
  const mapRef = useRef<google.maps.Map | null>(null)

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map
    setMapRef(map)
    if (markers.length === 1) {
      setMapCenter(markers[0][0].latitude, markers[0][0].longitude, 6)
      return
    }
    fitMapWithinMarkers(map, markers.flat())
  }

  return (
    <GoogleMap
      onLoad={handleLoad}
      mapContainerClassName={mapViewSt}
      zoom={6}
      options={{
        clickableIcons: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: true,
        disableDefaultUI: true,
        mapTypeId: satelliteView ? 'satellite' : 'roadmap',
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          // Hide transit station labels
          {
            featureType: 'transit.station',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          // Hide road labels
          {
            featureType: 'road',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          // Hide administrative parcel labels (e.g., property boundaries)
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          // Hide business labels
          {
            featureType: 'poi.business',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          // Show country and city names (administrative regions)
          {
            featureType: 'administrative.country',
            elementType: 'labels',
            stylers: [{ visibility: 'on' }],
          },
          {
            featureType: 'administrative.locality',
            elementType: 'labels',
            stylers: [{ visibility: 'on' }],
          },
          {
            featureType: 'administrative.province',
            elementType: 'labels',
            stylers: [{ visibility: 'on' }],
          },
        ],
      }}
    >
      {markers.map((markerGroup, index) => (
        <CustomMarker
          key={index}
          markers={markerGroup}
          markerColor={markerColor}
          satelliteView={satelliteView}
          scrollToRef={scrollToRef}
        />
      ))}
    </GoogleMap>
  )
}
