import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { Loader, ScanFAB } from 'src/components'
import { DEFAULT_LANGUAGE, PATHS } from 'src/constants'
import { uselAccountFirstRedirect } from 'src/contexts'
import { useGetAccountSimpleId, useGetProductRedirectUrls } from 'src/hooks'

import { EDefaultLandingPage } from 'src/types'

export default function Home() {
  const [, i18n] = useTranslation()
  const { productId, simpleProductId } = useParams()
  const navigate = useNavigate()

  // const { currentIndex, handleClose, handleNext, handleOpen, handlePrev, open, setCurrentIndex } =
  //   useBlockchainVerifiedModal()

  const { isRedirected } = uselAccountFirstRedirect()

  const { data, isLoading } = useGetProductRedirectUrls(simpleProductId, i18n.language)

  const { data: accountSimpleId, isLoading: accountSimpleIdIsLoading } = useGetAccountSimpleId(
    undefined,
    simpleProductId,
    data?.default_landing_page === EDefaultLandingPage.ACCOUNT,
  )

  // const { data: productScans, isLoading: isProductScansLoading } =
  //   useGetProductScans(simpleProductId)
  // const { isShowed, setIsShowed } = uselBlockchainModal()

  // useEffect(() => {
  //   if (!isShowed && !isProductScansLoading && productScans && productScans.length > 0) {
  //     handleOpen()
  //     setIsShowed(true)
  //   }
  // }, [isProductScansLoading])

  useEffect(() => {
    if (!isLoading && data) {
      if (data.override_url) {
        window.location.replace(data.override_url)
        return
      }

      if (data.default_landing_page && !data.override_url) {
        if (data.default_landing_page === EDefaultLandingPage.IMPACT) {
          navigate(
            i18n.language === DEFAULT_LANGUAGE
              ? `/${simpleProductId}/${PATHS.IMPACTS}`
              : `/${i18n.language}/${simpleProductId}/${PATHS.IMPACTS}`,
          )
          return
        }
        if (data.default_landing_page === EDefaultLandingPage.JOURNEY) {
          navigate(
            i18n.language === DEFAULT_LANGUAGE
              ? `/${simpleProductId}/${PATHS.JOURNEY}`
              : `/${i18n.language}/${simpleProductId}/${PATHS.JOURNEY}`,
          )
        }
        if (
          !isRedirected &&
          !accountSimpleIdIsLoading &&
          data.default_landing_page === EDefaultLandingPage.ACCOUNT
        ) {
          navigate(
            i18n.language === DEFAULT_LANGUAGE
              ? `/${PATHS.ACCOUNT}/${accountSimpleId}`
              : `/${i18n.language}/${PATHS.ACCOUNT}/${accountSimpleId}`,
          )
        }
        // if (data.default_landing_page === EDefaultLandingPage.HOME) {
        //   navigate(
        //     i18n.language === DEFAULT_LANGUAGE
        //       ? `/${simpleProductId}`
        //       : `/${i18n.language}/${simpleProductId}`,
        //   )
        // }
        return
      }
    }
  }, [isLoading, accountSimpleIdIsLoading])

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Outlet />
      {(productId || simpleProductId) && <ScanFAB />}
      {/* {productScans && productScans.length > 0 && (
        <BlockchainVerifiedModal
          currentIndex={currentIndex}
          handleClose={handleClose}
          handleNext={handleNext}
          handlePrev={handlePrev}
          open={open}
          scans={productScans.slice(0, 10)}
          setCurrentIndex={setCurrentIndex}
        />
      )} */}
    </>
  )
}
