import axios from 'axios'

import { HttpService } from './http.service'

import { IAccount, IAccountImpact } from 'src/types'
import { STORAGE_KEYS } from 'src/constants'

class AccountService extends HttpService {
  constructor() {
    super(axios)
  }

  getAccounts() {
    return this.get<IAccount[]>('account')
  }

  getAccount(id: string, locale: string) {
    return this.get<IAccount>(`account/${id}/${locale}`)
  }

  getAccountImpacts(locale: string, accountId: string, productId: string) {
    const guestId = localStorage.getItem(STORAGE_KEYS.GUEST_ID)

    if (!accountId && productId) {
      return this.get<IAccountImpact[]>(
        `account/${productId}/${locale}/impacts?isProductId=true&guestId=${guestId}`,
      )
    }
    return this.get<IAccountImpact[]>(`account/${accountId}/${locale}/impacts?guestId=${guestId}`)
  }

  getAccountSimpleId(accountId: string, productId?: string) {
    if (!accountId && productId) {
      return this.get<string>(`account/simple-id/product/${productId}`)
    }
    return this.get<string>(`account/simple-id/${accountId}`)
  }
}

export const accountService = new AccountService()
